<template>
  <div>
    <h2>商品一覧</h2>
  </div>
</template>

<script>
export default {
  name: 'List'
}
</script>
